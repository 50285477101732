import { render, staticRenderFns } from "./List.vue?vue&type=template&id=30fe3859&"
import script from "./List.vue?vue&type=script&lang=js&"
export * from "./List.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports